import { graphql } from "gatsby";
import { useEffect } from "react";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import "../assets/scss/blog.scss";
import Seo from "../components/seo";
import { addCursor, toUppercase, transformMonth } from "../util";
import PageWrapper from "./page-wrapper";

const BlogPost = ({ data }) => {
  const { id, html, excerpt, frontmatter, fields, timeToRead } =
    data.markdownRemark;
  const title = toUppercase(frontmatter.title);
  const date = transformMonth(frontmatter.date);

  useEffect(() => {
    addCursor();

    document.querySelectorAll("a").forEach((link) => {
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
    });
  }, []);

  return (
    <PageWrapper key={id}>
      <Seo
        title={title}
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        isBlogPost={true}
        // datePublished={date}
        readTime={
          timeToRead > 1 ? `${timeToRead} Minutes` : `${timeToRead} Minute`
        }
        pathname={fields.slug}
      />
      {/* <div className="preloader preloader__blog"></div> */}
      <Navigation>
        {/* <div className="blog__progress_indicator"></div> */}
      </Navigation>
      <main>
        <article>
          <BlogHeader
            role="PRODUCT DESIGN, BACKEND DEVELOPMENT"
            title={title}
            datePusblished={date}
          />
          <section className="blog__body">
            <div className="layout blog__body_content">
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </section>
        </article>
      </main>
      <Footer />
    </PageWrapper>
  );
};

export const BlogPostTemplateQuery = graphql`
  query BlogPostTemplateQuery($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      html
      timeToRead
      excerpt(pruneLength: 160, format: PLAIN, truncate: true)
      frontmatter {
        title
        description
        date
        # featuredImage {
        #   childImageSharp {
        #     fixed(width: 200) {
        #       ...GatsbyImageSharpFixed
        #     }
        #   }
        # }
      }
    }
  }
`;

export default BlogPost;

const BlogHeader = ({ role, title, datePusblished }) => {
  return (
    <section aria-label="blog_post_title" className="blog__header layout">
      <div>
        <p className="blog__header_role">{role}</p>
        <h1 id="blog_post_title" className="blog__header_title">
          {title}
        </h1>
        <p className="blog__header_published">{datePusblished}</p>
      </div>
    </section>
  );
};
